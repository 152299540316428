import React, { ReactNode } from 'react';
import useFadeInObserver from '../hooks/useObserver';

interface FadeInSectionProps {
  children: ReactNode; // 声明 children 类型
  customClassName?: string; // 可选的 className prop
  customActiveClassName?: string; // 可选的 className prop
  onAdd?: () => void; // 父组件传递的回调函数，当类名被添加时触发
  onRemove?: () => void; // 父组件传递的回调函数，当类名被移除时触发
}

const FadeInSection: React.FC<FadeInSectionProps> = ({
  children,
  customClassName = '',
  customActiveClassName = 'visible',
  onAdd, // 接受 onAdd 回调
  onRemove // 接受 onRemove 回调
}) => {
const ref = useFadeInObserver({ 
  customActiveClassName: customActiveClassName,
  onAdd, // 接受 onAdd 回调
  onRemove // 接受 onRemove 回调
});

  return (
    <div ref={ref} className={`${customClassName}`}>
      {children}
    </div>
  );
};

export default FadeInSection;
